import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "#i18n/en.json";
import zh_TW from "#i18n/zh-TW.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      "zh-TW": { translation: zh_TW },
      "zh-CN": { translation: zh_TW },
      "zh-HK": { translation: zh_TW },
      "zh-MO": { translation: zh_TW },
      "zh-SG": { translation: zh_TW },
      "zh-CHT": { translation: zh_TW },
      "zh-CHS": { translation: zh_TW },
      zh_cht: { translation: zh_TW },
      zh_chs: { translation: zh_TW },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
