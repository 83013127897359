import dayjs from "dayjs";

/**
 *
 * @param locale 地區
 * @returns 中文地區：繁體中文；非中文地區：英文
 */
export const getLocale = (locale: string) => {
  if (locale.startsWith("zh-")) {
    return "zh-TW";
  }
  return "en";
};

/**
 * 用作 route 的 mapping
 * @param name 遊戲名稱
 * @returns kebab-case 的遊戲名稱
 */
export const gameNameParser = (name: string) =>
  name.toLowerCase().replace(/ /g, "-");

/**
 * 用作 Array.sort 的 comparator
 * @param a 日期字串 a
 * @param b 日期字串 b
 * @param asc 是否由小（遠）排到大（近），預設由大（近）排到小（遠）
 * @returns
 */
export const dateSortComparator = (a: string, b: string, asc = false) =>
  dayjs(a).diff(dayjs(b)) * (asc ? 1 : -1);

/**
 * 以 boolean property 排序時，將 true 往前排
 * @param a
 * @param b
 * @param key a, b 的 property
 * @returns
 */
export const booleanSortComparator = <T>(a: T, b: T, key: keyof T) => {
  if (a[key] && !b[key]) {
    return -1;
  } else if (b[key] && !a[key]) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * 把數字加上千分位逗號
 * @param number
 * @returns
 */
export const numberWithCommas = (number: number | string) => {
  const [int, dec] =
    typeof number === "number"
      ? number.toString().split(".")
      : number.split(".");
  return int.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (dec ? `.${dec}` : "");
};

/**
 * 大寫化（字首大寫）
 * @param string
 * @returns
 */
export const capitalize = (string: string) => {
  if (string.length <= 1) return string.toUpperCase();
  return string[0].toUpperCase() + string.slice(1);
};

/**
 * 將markdown檔案轉換成React-Markdown可以使用空行的格式
 * @param string
 * @returns
 */
export const markdownBlankLineFormator = (string: string) => {
  return string.replace(/\n/gi, "&nbsp; \n");
};

