import { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";
import classNames from "classnames";

import styles from "./style.module.scss";

const ToTop = () => {
  const [showButton, setShowButton] = useState(false);

  const handleShowButton = () => {
    setShowButton(window.scrollY >= 300);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <div
      className={
        showButton ? classNames(styles.toTop, styles.show) : styles.toTop
      }
    >
      <IconButton onClick={scrollToTop} aria-label="Scroll to top">
        <KeyboardArrowUpIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

export default ToTop;
