import classNames from "classnames";

import styles from "./style.module.scss";

const Scale = ({ number }: { number: number }) => {
  return (
    <ul className={styles.scale}>
      {Array.from({ length: 5 }, (_, i) => i + 1).map((i) => (
        <li
          key={i}
          className={classNames(
            styles.scale__item,
            number >= i ? styles.fill : ""
          )}
        ></li>
      ))}
    </ul>
  );
};

export default Scale;
