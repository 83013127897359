import { createTheme } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#d51427",
    },
    text: {
      primary: "#fefefe",
      secondary: "#202020",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: "scrollable",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          color: theme.palette.text.primary,
          fontSize: "16px",
          textTransform: "none",
        }),
        selected: ({ _, theme }) => ({
          color: theme.palette.primary.main,
          fontWeight: "bold",
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          "&.MuiAutocomplete-paper": {
            backgroundColor: theme.palette.text.secondary,
          },
          "&.MuiMenu-paper": {
            backgroundColor: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: ({ _, theme }) => ({
          color: theme.palette.text.primary,
        }),
        listbox: {
          '.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: "transparent",
          },
          '.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.text.primary,
            legend: {
              height: "14px",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.text.primary,
            borderWidth: "1px",
          },
        }),
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: 0,
          "@media (max-width: 768px)": {
            alignItems: "center",
          },
        },
      },
    },
    MuiTimelineSeparator: {
      styleOverrides: {
        root: {
          // 讓 Dot 能對齊 Content 的標題
          marginTop: 21,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          position: "relative",
          marginTop: 0,
          marginBottom: 0,
          borderColor: theme.palette.text.primary,
        }),
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          // 把 Connector 延伸到下一個 Dot 上緣
          position: "relative",
          backgroundColor: theme.palette.text.primary,

          "&::after": {
            content: "''",
            position: "absolute",
            bottom: "-21px",
            left: 0,
            backgroundColor: "inherit",
            width: "100%",
            height: "21px",
          },
        }),
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          "@media (max-width: 768px)": {
            marginBottom: "10px",
          },

          "&.timeline-year-label": {
            position: "absolute",
            top: "50%",
            left: "50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            zIndex: 1,

            "> span": {
              backgroundColor: theme.palette.text.primary,
              padding: "5px 30px",
              color: theme.palette.text.secondary,
              fontWeight: "bold",
              borderRadius: "5px",
            },

            "@media (max-width: 768px)": {
              position: "static",
              transform: "none",
              display: "flex",
              maxWidth: "500px",
              marginBottom: "0",
              padding: "6px 16px",
              alignItems: "center",
            },
          },
        }),
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          position: "relative",
          // 768px 以下，把 Separator 左邊的 Contents 都隱藏
          "&::before": {
            "@media (max-width: 768px)": {
              padding: 0,
              flex: "none",
            },
          },
          // 最後一個 Item 的 Connector 不要往下延伸
          "&:last-child .MuiTimelineConnector-root::after": {
            content: "none",
          },
          // 原點旁連接到 Content 的線
          ".MuiTimelineDot-root::after": {
            content: "''",
            position: "absolute",
            top: "50%",
            backgroundColor: theme.palette.text.primary,
            width: "16px",
            height: "2px",
          },
          // 原點旁連接到 Content 的線。奇數往右連
          "&:nth-of-type(odd) .MuiTimelineDot-root::after": {
            left: "100%",
            transform: "translate(2px, -50%)",
          },
          // 原點旁連接到 Content 的線。偶數往左連
          "&:nth-of-type(even) .MuiTimelineDot-root::after": {
            right: "100%",
            transform: "translate(-2px, -50%)",
            // 原點旁連接到 Content 的線。768px 以下都是往右
            "@media (max-width: 768px)": {
              left: "100%",
              transform: "translate(2px, -50%)",
            },
          },
          "&.timeline-year-label": {
            "@media (max-width: 768px)": {
              width: "100%",
              maxWidth: "544px",
              minHeight: "56px",
            },
          },
        }),
      },
    },
  },
});
